import {  useAppSelector } from "../../app/hooks"
import { BookIcon } from "../../components/GameIcon"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameEducation } from "./gameSlice"

export const EducationMeter = () => {
  const turnEducation = useAppSelector(selectGameEducation);
  const { educationModifier } = useGameTriggerContext();

  return <MeterBar value={turnEducation} max={100} icon={<BookIcon />} modifier={educationModifier} />
}

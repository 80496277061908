import { useAppSelector } from "../../app/hooks"
import { HeartIcon } from "../../components/GameIcon"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameHealth } from "./gameSlice"

export const HealthMeter = () => {
  const turnHealth = useAppSelector(selectGameHealth)
  const { healthModifier } = useGameTriggerContext()

  return <MeterBar value={turnHealth} max={100} icon={<HeartIcon />} modifier={healthModifier}/>
}

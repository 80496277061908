import { Box, Flex, Image } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { EducationMeter } from "../features/game/EducationMeter"
import { HealthMeter } from "../features/game/HealthMeter"
import { RelationshipMeter } from "../features/game/RelationshipMeter"
import SlideViewer from "../features/game/SlideViewer"
import { CharacterText } from "../features/character/CharacterText"
import { AgeCounter } from "../features/game/AgeCounter"

const MotionBox = Box

const GameSlidePage: React.FC = () => {
  return (
    <Box p={4}>
      {/* Character Name and Age */}
      <Box textAlign="center" mb={4}>
        <Flex direction="row" alignItems="center" justifyContent="center">
          <CharacterText />
          <Box mx={2} />{" "}
          {/* Add some spacing between the text and the counter */}
          <AgeCounter />
        </Flex>
      </Box>

      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
      >
        {/* Left Side: Meters with Drag Animation */}
        <Flex
          direction={{ base: "row", md: "column" }}
          alignItems="center"
          justifyContent="center"
          mr={{ base: 0, md: 4 }}
          mb={{ base: 4, md: 0 }}
        >
          <MotionBox mb={{ base: 0, md: 2 }} mr={{ base: 2, md: 0 }}>
            <EducationMeter />
          </MotionBox>
          <MotionBox mb={{ base: 0, md: 2 }} mr={{ base: 2, md: 0 }}>
            <HealthMeter />
          </MotionBox>
          <MotionBox>
            <RelationshipMeter />
          </MotionBox>
        </Flex>

        {/* Center: SlideViewer */}
        <Box
          flexShrink={0}
          width={{ base: "100%", md: "75vh" }}
          aspectRatio={1}
          position="relative"
        >
          <SlideViewer />
        </Box>
      </Flex>
    </Box>
  )
}

export default GameSlidePage

import {  useAppSelector } from "../../app/hooks"
import { FireIcon } from "../../components/GameIcon"
import MeterBar from "../../components/MeterBar"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { selectGameRelationship } from "./gameSlice"

export const RelationshipMeter = () => {
  const turnRelationship = useAppSelector(selectGameRelationship)
  const { relationshipModifier } = useGameTriggerContext()
  return <MeterBar value={turnRelationship} max={100} icon={<FireIcon/>} modifier={relationshipModifier} />
}

import type { GameLogicStatic } from "@types"

// Templates Strings
// Stats {$age}, {$choices}, {$duration}
// Meters {$education}, {$relationship}, {$health}\

const defaultGameData: GameLogicStatic = {
  rules: {
    age: { lt: 25 },
    education: { gt: 0, lt: 100 },
    relationship: { gt: 0, lt: 100 },
    health: { gt: 0, lt: 100 },
  },
  start: {
    turn: 0,
    duration: 0,
    age: 0,
    education: 25,
    relationship: 25,
    health: 25,
    slide: {
      title: { en: "Welcome to My Choices" },
      description: { en: "Press Continue" },
      image: "/default_slide.webp",
      imageAlt: "slide alt text",
      type: "choice",
      selections: [
        {
          title: { en: "Continue" },
          icon: "positive",
        },
      ],
    },
    tags: [],
    effects: {
      age: 1,
    },
  },
  content: [
    {
      title: "Example Deck",
      groups: [
        {
          title: "Example Group",
          slides: [],
          triggers: [
            {
              title: { en: "Mother" },
              description: { en: "You have a child" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "trigger",
              requirements: {
                tags: { has: "is_pregnant" },
              },
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    health: -10,
                    tags: {
                      remove: ["is_pregnant"],
                    },
                  },
                },
              ],
            },
            {
              title: { en: "School Dropout" },
              description: { en: "You drop out of school" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "trigger",
              requirements: {
                tags: { has: "is_school_dropout" },
              },
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    education: -10,
                    tags: {
                      remove: ["is_school_dropout"],
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          title: "Starting Events",
          requirements: {
            age: { eq: 0 },
          },
          slides: [
            {
              title: { en: "Wealth" },
              description: { en: "You are born to wealthy parents." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    health: 10,
                  },
                },
              ],
            },
            {
              title: { en: "Abuse" },
              description: { en: "You have an abusive parent." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    tags: {
                      add: ["has_abusive_parent"],
                    },
                    relationship: -10,
                  },
                },
              ],
            },
            {
              title: { en: "Under Educated" },
              description: { en: "Your parents are under educated." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    tags: {
                      add: ["is_under_educated"],
                    },
                    education: -10,
                  },
                },
              ],
            },
            {
              title: { en: "Health Condition" },
              description: { en: "You are born with a health condition." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    health: -10,
                  },
                },
              ],
            },
            {
              title: { en: "Well Educated" },
              description: { en: "Your parents are well educated." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    education: 10,
                  },
                },
              ],
            },
            {
              title: { en: "Supportive" },
              description: { en: "Your parents are supportive." },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    relationship: 10,
                  },
                },
              ],
            },
          ],
        },
        {
          "title": "Early Primary School ",
          "requirements": {
            "age": {
              "gt": 1,
              "lt": 8
            }
          },
          "slides": [
            {
              "title": {
                "en": "Unexpected Shock"
              },
              "description": {
                "en": "A young African girl, around 10 years old, wearing a school uniform sits and looks down in shock."
              },
              "image": "/images/02-early-primary-school_young-girl-shocked.webp",
              "imageAlt": "Black-and-white comic book style image of a young African girl in a school uniform sitting and looking down with a shocked expression",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Seek Comfort"
                  },
                  "effects": {
                    "health": 5,
                    "tags": {
                      "add": [
                        "supported"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Face the Challenge"
                  },
                  "effects": {
                    "health": 4,
                    "tags": {
                      "add": [
                        "resilient"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Water Fetching"
              },
              "description": {
                "en": "A young girl in rural Zambia walks along a dusty path carrying a bucket to fetch water, surrounded by spars."
              },
              "image": "/images/02-early-primary-school_young-girl-fetching-water.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia walking with a bucket to fetch water, surrounded by spars",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Carry the Load"
                  },
                  "effects": {
                    "health": 3,
                    "tags": {
                      "add": [
                        "determined"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Take a Break"
                  },
                  "effects": {
                    "health": 2,
                    "tags": {
                      "add": [
                        "rested"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Helping with Cooking"
              },
              "description": {
                "en": "A young girl in rural Zambia helps her mother cook over an open fire, carefully tending to the meal."
              },
              "image": "/images/02-early-primary-school_young-girl-helping-cook.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia helping her mother cook over an open fire, carefully tending to the meal",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Learn Cooking Skills"
                  },
                  "effects": {
                    "education": 3,
                    "tags": {
                      "add": [
                        "culinary"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Bond with Mother"
                  },
                  "effects": {
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "family"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Caring for Animals"
              },
              "description": {
                "en": "A young girl in rural Zambia gently feeds chickens and goats, nurturing the family's livestock."
              },
              "image": "/images/02-early-primary-school_young-girl-caring-animals.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia feeding chickens and goats with care",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Nurture the Livestock"
                  },
                  "effects": {
                    "health": 3,
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "animalCare"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Learn Advanced Animal Husbandry"
                  },
                  "effects": {
                    "education": 4,
                    "tags": {
                      "add": [
                        "education"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Learning to Weave"
              },
              "description": {
                "en": "A young girl in rural Zambia is seated and focused on learning to weave or mend a basket, carefully tending to her work."
              },
              "image": "/images/02-early-primary-school_young-girl-weaving.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia seated and focused on weaving or mending a basket",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Master the Craft"
                  },
                  "effects": {
                    "education": 4,
                    "tags": {
                      "add": [
                        "artisan"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Seek Guidance"
                  },
                  "effects": {
                    "relationship": 3,
                    "tags": {
                      "add": [
                        "mentored"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Drying Clothes"
              },
              "description": {
                "en": "A young girl in rural Zambia dries clothes after washing, carefully hanging them on a simple line under the open sky."
              },
              "image": "/images/02-early-primary-school_young-girl-drying-clothes.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia drying clothes by hanging them on a simple line",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Optimize Drying Technique"
                  },
                  "effects": {
                    "education": 3,
                    "tags": {
                      "add": [
                        "skillDevelopment"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Take a Short Break"
                  },
                  "effects": {
                    "health": 2,
                    "tags": {
                      "add": [
                        "rested"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Maintaining Peace"
              },
              "description": {
                "en": "A young girl in rural Zambia learns not to disturb her parents during an argument, standing quietly in the background."
              },
              "image": "/images/02-early-primary-school_young-girl-maintaining-peace.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia standing quietly while her parents argue",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Stay Calm and Observe"
                  },
                  "effects": {
                    "relationship": 2,
                    "education": 1,
                    "tags": {
                      "add": [
                        "peaceKeeper"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Seek Guidance"
                  },
                  "effects": {
                    "education": 3,
                    "relationship": 1,
                    "tags": {
                      "add": [
                        "mentored"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Patiently Waiting"
              },
              "description": {
                "en": "A young girl in rural Zambia patiently waits to eat after her parents finish working, sitting quietly and reflecting."
              },
              "image": "/images/02-early-primary-school_young-girl-waiting-to-eat.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia patiently waiting to eat after her parents finish working, sitting quietly",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Use Waiting Time to Study"
                  },
                  "effects": {
                    "education": 3,
                    "tags": {
                      "add": [
                        "student"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Engage in Conversation"
                  },
                  "effects": {
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "familyBond"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Comforting a Sibling"
              },
              "description": {
                "en": "A young girl in rural Zambia learns to comfort her younger sibling during difficult times, gently embracing and supporting them."
              },
              "image": "/images/02-early-primary-school_young-girl-comforting-sibling.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia comforting her younger sibling during difficult times",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Offer a Listening Ear"
                  },
                  "effects": {
                    "relationship": 3,
                    "tags": {
                      "add": [
                        "supportive"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Encourage Positive Thinking"
                  },
                  "effects": {
                    "education": 2,
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "mentor"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Sharing Secrets"
              },
              "description": {
                "en": "A young girl in rural Zambia shares secrets with her classmates at school, leaning close to a friend and building strong bonds."
              },
              "image": "/images/02-early-primary-school_young-girl-sharing-secrets.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia sharing secrets with her classmates at school, leaning close to a friend",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Build Trust"
                  },
                  "effects": {
                    "relationship": 3,
                    "tags": {
                      "add": [
                        "trustedFriend"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Stay Focused on Studies"
                  },
                  "effects": {
                    "education": 2,
                    "tags": {
                      "add": [
                        "dedicatedStudent"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Eager to Learn"
              },
              "description": {
                "en": "A young girl in rural Zambia raises her hand enthusiastically in class, sitting among other attentive students."
              },
              "image": "/images/02-early-primary-school_young-girl-raising-hand.webp",
              "imageAlt": "Black-and-white comic book style image of a young girl in rural Zambia raising her hand enthusiastically in a classroom with other students",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Answer the Question"
                  },
                  "effects": {
                    "education": 3,
                    "tags": {
                      "add": [
                        "activeParticipant"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Encourage a Classmate"
                  },
                  "effects": {
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "supportive"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Eavesdropping at School"
              },
              "description": {
                "en": "A young African schoolgirl in rural Zambia, wearing a traditional school uniform, quietly eavesdrops on a conversation between three boys."
              },
              "image": "/images/02-early-primary-school_young-girl-eavesdropping-school.webp",
              "imageAlt": "Black-and-white comic book style image of a young African schoolgirl in rural Zambia, wearing a traditional school uniform, quietly eavesdropping on a conversation between three boys",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Confront the Boys"
                  },
                  "effects": {
                    "relationship": -2,
                    "education": 1,
                    "tags": {
                      "add": [
                        "conflict"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Stay Hidden and Observe"
                  },
                  "effects": {
                    "education": 2,
                    "tags": {
                      "add": [
                        "observer"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Inform a Trusted Teacher"
                  },
                  "effects": {
                    "relationship": 3,
                    "education": 1,
                    "tags": {
                      "add": [
                        "responsible"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Journey Home"
              },
              "description": {
                "en": "A young African girl around 10 years old walks along the side of a rural road on her way home. A driver approaches her vehicle."
              },
              "image": "/images/02-early-primary-school_young-girl-journey-home.webp",
              "imageAlt": "Black-and-white comic book style image of a young African girl around 10 years old walking along a rural road with a driver approaching her vehicle",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Greet the Driver"
                  },
                  "effects": {
                    "relationship": 2,
                    "tags": {
                      "add": [
                        "friendly"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Continue Walking Safely"
                  },
                  "effects": {
                    "health": 3,
                    "tags": {
                      "add": [
                        "safeJourney"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Ask for Help"
                  },
                  "effects": {
                    "relationship": 3,
                    "education": 1,
                    "tags": {
                      "add": [
                        "assistance"
                      ]
                    }
                  }
                }
              ]
            },
            {
              "title": {
                "en": "Alone Behind the Hut"
              },
              "description": {
                "en": "A young African girl, around 10 years old, sits sulking alone behind a simple thatch-roof hut, feeling isolated and upset."
              },
              "image": "/images/02-early-primary-school_young-girl-sulking-hut.webp",
              "imageAlt": "Black-and-white comic book style image of a young African girl around 10 years old sulking alone behind a simple thatch-roof hut",
              "type": "event",
              "selections": [
                {
                  "title": {
                    "en": "Seek Comfort from a Friend"
                  },
                  "effects": {
                    "relationship": 3,
                    "health": 2,
                    "tags": {
                      "add": [
                        "supportiveFriend"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Reflect Quietly"
                  },
                  "effects": {
                    "education": 2,
                    "health": 1,
                    "tags": {
                      "add": [
                        "selfReflection"
                      ]
                    }
                  }
                },
                {
                  "title": {
                    "en": "Talk to a Trusted Adult"
                  },
                  "effects": {
                    "relationship": 2,
                    "health": 3,
                    "tags": {
                      "add": [
                        "mentored"
                      ]
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          title: "Late Primary School",
          requirements: {
            age: { gt: 9, lt: 12 },
          },
          slides: [
            {
              title: { en: "Late Primary School" },
              description: { en: "You are in Late Primary School" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    relationship: 10,
                  },
                },
              ],
            },
          ],
        },
        {
          title: "Early Secondary School",
          requirements: {
            age: { gt: 13, lt: 15 },
          },
          slides: [
            {
              title: { en: "Early Secondary School" },
              description: { en: "You are in Early Secondary School" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    relationship: 10,
                  },
                },
              ],
            },
          ],
        },
        {
          title: "Late Secondary School",
          requirements: {
            age: { gt: 16, lt: 18 },
          },
          slides: [
            {
              title: { en: "Late Secondary School" },
              description: { en: "You are in Late Secondary School" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    relationship: 10,
                  },
                },
              ],
            },
          ],
        },
        {
          title: "Early Adulthood",
          requirements: {
            age: { gt: 19, lt: 21 },
          },
          slides: [
            {
              title: { en: "Early Adulthood" },
              description: { en: "You are in Early Adulthood" },
              image: "/default_slide.webp",
              imageAlt: "slide alt text",
              type: "event",
              selections: [
                {
                  title: { en: "Continue" },
                  effects: {
                    relationship: 10,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

export default defaultGameData

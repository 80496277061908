import type { PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../app/createAppSlice"

import type { GameLogicModifiers, GameLogicState, SlideGeneric } from "@types"
import defaultGameData from "../default"

// Load default from JSON
export const gameInitialState: GameLogicState = defaultGameData.start;

export const gameSlice = createAppSlice({
    name: "game",
    initialState: gameInitialState,
    reducers: create => ({
      incrementGameTurn: create.reducer(state => {
        state.turn += 1
      }),
      resetGame: create.reducer(() => gameInitialState),
      updateGame: create.reducer((state, action: PayloadAction<GameLogicModifiers>) => {
        state.age += action.payload?.age ?? 0
        state.education += action.payload?.education ?? 0
        state.health += action.payload?.health ?? 0
        state.relationship += action.payload?.relationship ?? 0
      }),
      updateDuration: create.reducer((state, action: PayloadAction<number>) => {
        state.duration += action.payload
      }),
      updateGameAge: create.reducer((state, action: PayloadAction<number>) => {
        state.age += action.payload
      }),
      updateGameEducation: create.reducer((state, action: PayloadAction<number>) => {
        state.education += action.payload
      }),
      updateGameHealth: create.reducer((state, action: PayloadAction<number>) => {
        state.health += action.payload
      }),
      updateGameRelationship: create.reducer(
        (state, action: PayloadAction<number>) => {
          state.relationship += action.payload
        },
      ),
      updateGameSlide: create.reducer(
        (state, action: PayloadAction<SlideGeneric>) => {
          state.slide = action.payload
        },
      ),
    }),
    // You can define your selectors here. These selectors receive the slice
    // state as their first argument.
    selectors: {
      // State
      selectIsDone: state => state?.isDone ?? false,
      selectGameAge: state => state.age,
      selectGameEducation: state => state.education,
      selectGameHealth: state => state.health,
      selectGameRelationship: state => state.relationship,
      selectGameTags: state => state.tags,
      // Slide
      selectSlide: state => state.slide,
      // Statistics
      selectDuration: state => state.duration,
      selectTurn: state => state.turn,
    },
  })
  
  export const {
    incrementGameTurn,
    updateGame,
    updateGameAge,
    updateGameEducation,
    updateGameHealth,
    updateGameRelationship,
    updateGameSlide,
    resetGame,
  } = gameSlice.actions
  
  export const {
    selectIsDone,
    selectGameAge,
    selectGameEducation,
    selectGameHealth,
    selectGameRelationship,
    selectGameTags,
    // Slide
    selectSlide,
    // Statistics
    selectDuration,
    selectTurn,
  } = gameSlice.selectors
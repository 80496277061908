import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react"
import { HamburgerIcon, RepeatIcon } from "@chakra-ui/icons"
import GameSlidePage from "./pages/GameSlidePage"
import { resetGame } from "./features/game/gameSlice"
import { useAppDispatch } from "./app/hooks"
import { MenuCharacterTextEditor } from "./features/character/CharacterText"

const App = () => {
  const dispatch = useAppDispatch()

  const onReset = () => {
    dispatch(resetGame())
  }

  return (
    <Box position="relative" height="100vh">
      {/* Navigation Menu */}
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
          size="sm"
          m={2}
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
        />
        <MenuList>
          <MenuCharacterTextEditor />
          <MenuItem onClick={onReset}> <RepeatIcon mr={2} /> Restart Game</MenuItem>
        </MenuList>

      </Menu>

      {/* Container for GameSlidePage */}
      <Box
        height="100%"
      >
        <GameSlidePage />
      </Box>
    </Box>
  )
}

export default App
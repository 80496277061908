import { Flex, Heading, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, ModalFooter, Button, Text, MenuItem } from "@chakra-ui/react"
import { EditIcon } from "@chakra-ui/icons"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCharacterName, updateCharacterName } from "./characterSlice"

export const CharacterText: React.FC = () => {
  const characterName = useAppSelector(selectCharacterName)
  return (
    <div>
      <Flex alignItems="center">
        <Heading as="h1" mr={2}>{characterName}</Heading>
      </Flex>
    </div>
  )
}


  export const MenuCharacterTextEditor: React.FC = () => {
    const dispatch = useAppDispatch()
    const characterName = useAppSelector(selectCharacterName)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [inputText, setInputText] = useState(characterName)
  
    const handleEditClick = () => setIsModalOpen(true)
    const handleCloseModal = () => setIsModalOpen(false)
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)
    const handleSaveClick = () => {
      dispatch(updateCharacterName(inputText))
      setIsModalOpen(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSaveClick()
      }
    }
  
    return (
      <MenuItem onClick={handleEditClick}>
        <Flex alignItems="center">
          <EditIcon  aria-label="Edit character name"  mr={2} />
          <Text >Edit Name</Text>
        </Flex>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Your Name</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input type="text" value={inputText} onChange={handleInputChange} onKeyDown={handleKeyDown} />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSaveClick}>
                Save
              </Button>
              <Button variant="ghost" onClick={handleCloseModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </MenuItem>
    )
  }
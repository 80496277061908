import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../app/createAppSlice";
import type { GameLogicCharacter } from "@types";

// Load default from JSON
export const characterInitialState: GameLogicCharacter = {
  name: "Your Name"
}

export const characterSlice = createAppSlice({
  name: "character",
  initialState: characterInitialState,
  reducers: create => ({
    updateCharacterName: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.name = action.payload
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    // State
    selectCharacterName: character => character.name
  },
})

// Action creators are generated for each case reducer function.
export const {
 updateCharacterName
} = characterSlice.actions

// Selectors regameed by `slice.selectors` take the root state as their first argument.
export const {
  selectCharacterName
} = characterSlice.selectors

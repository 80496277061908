import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react"
import { motion } from "framer-motion"

const MeterBar: React.FC<{
  value: number
  max: number
  icon: React.ReactNode
  modifier: number
}> = ({ value, max, icon, modifier }) => {
  // Calculate the total percentage considering value and modifier
  const percentage = Math.min((value / max) * 100, 100) // Ensure it does not exceed 100%

  // Determine color based on modifier
  const color = modifier !== 0 ? "#003300" : "#008000" // Use a different color when modifier is not zero

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <motion.div
        style={{
          width: '100%',
          borderRadius: '5px',
        }}
        animate={{ color: color }}
        initial={{ color: '#008000' }}
        transition={{
          color: { duration: 0.25, ease: 'easeInOut' },
        }}
      >
        <CircularProgress
          value={percentage}
          color={color}
          thickness="10px"
          size="100px"
          trackColor="#d3d3d3"
          capIsRound
        >
          <CircularProgressLabel>
            {icon}
          </CircularProgressLabel>
        </CircularProgress>
      </motion.div>
    </div>
  )
}

export default MeterBar

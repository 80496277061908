import {
  Button,
  Stack,
  Image,
  Box,
} from "@chakra-ui/react"
import defaultGameData from "../default"
import type { BaseSelection } from "@types"
import { GameText } from "../../components/GameText"
import { GameHeader } from "../../components/GameHeader"
import { motion } from "framer-motion"
import { lookupGameIcon } from "../../components/GameIcon"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import {
  checkGameRules,
  getRandomValidSlide,
  processTurn,
} from "../../app/gameLogic"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  incrementGameTurn,
  resetGame,
  selectSlide,
  updateGame,
  updateGameAge,
  updateGameEducation,
  updateGameHealth,
  updateGameRelationship,
  updateGameSlide,
} from "./gameSlice"

const SlideViewer: React.FC = () => {
  const currentSlide = useAppSelector(selectSlide)
  const data = currentSlide ?? defaultGameData.content[0].groups[0].slides[0]

  const slide = currentSlide ?? data

  if (!slide) {
    return null
  }

  return (
    <Box>
      {/* Image with aspect ratio 1, centered, not cropped */}
      <Box
        position="relative"
        width="100%"
        maxH="50vh"
        paddingTop="100%" // This creates a square aspect ratio
        // overflow="hidden"
      >
        <Image
          src={slide.image}
          alt={slide.imageAlt}
          objectFit="contain"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
        />
        {/* Overlay Game Header and Text over bottom third */}
        <Box
          position="absolute"
          bottom={0}
          left={0}
          width="100%"
          bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background
          color="white"
          p={4}
        >
          <GameHeader text={data.title} />
          <GameText text={data.description} />
          {/* <Text fontSize="lg" fontWeight="bold">
            
            {slide.title}
          </Text>
          <Text>{slide.text}</Text> */}
        </Box>
      </Box>
      {/* SlideButtonGroup below the image, full width */}
      <Box width="100%" mt={4}>
        <SlideButtonGroup selections={slide.selections} />
      </Box>
    </Box>
  )
}


const SlideButtonGroup = (props: { selections: BaseSelection[] }) => {
  if (props.selections.length === 0) {
    return <ResetButton />
  }

  return (
    <Stack
      spacing={4}
      mt={6}
      width="100%"
      direction={{ base: "column", md: "row" }}
      wrap="wrap"
      justifyContent="center"
    >
      {props.selections.map((selection, index) => (
        <SlideButton key={index} action={selection} />
      ))}
    </Stack>
  )
}

export const ResetButton = () => {
  const dispatch = useAppDispatch()

  const onReset = () => {
    dispatch(resetGame())
  }

  return (
    <div style={{ width: "100%" }}>
      <Button onClick={onReset} colorScheme="red" mt="4" float="right">
        Reset
      </Button>
    </div>
  )
}

const SlideButton = (props: { action: BaseSelection }) => {
  // Dispatch
  const dispatch = useAppDispatch()
  const gameData = useAppSelector(state => state.game)

  const icon = lookupGameIcon(props.action?.icon)
  const { setEducationModifier } = useGameTriggerContext()
  const { setHealthModifier } = useGameTriggerContext()
  const { setRelationshipModifier } = useGameTriggerContext()

  const onClick = () => {
    const turnEffects = gameData.effects
    console.log("Turn", turnEffects)
    dispatch(incrementGameTurn())
    dispatch(updateGame(turnEffects))

    console.log("Process action", props.action)
    if (props.action?.effects?.education) {
      dispatch(updateGameEducation(props.action.effects.education))
    }
    if (props.action?.effects?.health) {
      dispatch(updateGameHealth(props.action.effects.health))
    }
    if (props.action?.effects?.relationship) {
      dispatch(updateGameRelationship(props.action.effects.relationship))
    }
    if (props.action?.effects?.age) {
      dispatch(updateGameAge(props.action.effects.age))
    }

    const updatedGameData = processTurn(gameData, props.action)
    console.log("Updated stats", updatedGameData)
    const isGameOver = !checkGameRules(updatedGameData, defaultGameData.rules)
    if (isGameOver) {
      console.log("Game over")
      dispatch(
        updateGameSlide({
          title: { en: "Game Over" },
          description: { en: "You have died" },
          image: "/default_slide.webp",
          imageAlt: "slide alt text",
          type: "event",
          selections: [],
        }),
      )
      return
    }

    const newSlide = getRandomValidSlide(
      defaultGameData.content,
      updatedGameData,
    )
    if (newSlide) {
      console.log("New slide found")
      dispatch(updateGameSlide(newSlide))
    } else {
      console.log("No new slide found")
    }
  }

  const onHoverStart = () => {
    if (props.action?.effects?.education) {
      setEducationModifier(25)
    }
    if (props.action?.effects?.health) {
      setHealthModifier(25)
    }
    if (props.action?.effects?.relationship) {
      setRelationshipModifier(25)
    }
  }

  const onHoverEnd = () => {
    setEducationModifier(0)
    setHealthModifier(0)
    setRelationshipModifier(0)
  }

  return (
    <motion.a
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onTouchStartCapture={onHoverStart}
      onTouchEndCapture={onHoverEnd}
      onClick={onClick}
    >
      {icon ? (
        <Button aria-label="choice" leftIcon={icon} w="100%">
          <GameText text={props.action.title} />
        </Button>
      ) : (
        <Button aria-label="choice" w="100%">
          <GameText text={props.action.title} />
        </Button>
      )}
    </motion.a>
  )
}

export default SlideViewer
